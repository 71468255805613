@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');


.imgs {
    overflow: hidden;
    position: relative;
    /* display: inline-block; */
    /* justify-content: center; */
   /*  */
    /* align-items: center; */
    /* display: flex; */
  }

  .imgs::after {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0),rgba(6, 0, 0, 0.95));
  }


  .imgs p{
    font-family: 'Nunito Sans', sans-serif;
    font-size: x-large;
    /* color: black; */
    color: rgba(158, 113, 113, 0.868);
    position: absolute;
    justify-content: center;
    cursor: pointer;
    bottom:2px;
    z-index: 20;
    font-weight: 900;
    /* left :30% */
    text-align: center;

}


/* .imgs img{
    width: 60vw;
} */



  .imgs p:hover{
    color: rgb(255, 255, 255,1);
  }


  @media only screen and (max-width: 600px) {
    .imgs p{
      left: 37%;
      font-size: large;
      bottom: 0px;
    }
  }

.carousel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 600px;
    height: 400px;
  }
  
  .slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    height: 100%;
  }
  
  .slide-hidden {
    display: none;
  }
  
  .arrow {
    z-index: 10;
    position: absolute;
    filter: drop-shadow(0px 0px 5px #555);
    width: 2rem;
    height: 2rem;
    color: white;
  }
  
  .arrow:hover {
    cursor: pointer;
  }
  
  .arrow-left {
    left: 1rem;
  }
  
  .arrow-right {
    right: 1rem;
  }
  
  .indicators {
    display: flex;
    position: absolute;
    bottom: 1rem;
  }
  
  .indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border: none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
  }
  
  .indicator-inactive {
    background-color: grey;
  }