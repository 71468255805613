@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
body{
    box-sizing: border-box;
}
.videoe{
    position: relative;
    z-index:-10;
    width:100%;
    /* height: 100vh; */
}

.log{
    position: absolute;
    top:30%;
    left:5%;
    z-index:0
}

.log img{
    width: 400px;
}
.homse{
    color: white;
}
.homse h1{
    font-weight: bold;
    letter-spacing: 1px;
    margin-top: 20px;
    font-family: 'Nunito Sans', sans-serif;
}

.box img{
    margin-top: 30px;
    width: 100px;
    /* height: 100px; */
    font-family: 'Nunito Sans', sans-serif;

}

.box h1{
    color: white;
    font-size: large;
    font-family: 'Nunito Sans', sans-serif;


}

.box{

    justify-content: center;
    align-items: center;
    /* display: inline-block; */
    margin:10px;
    width: 270px;
    height:270px;
    background-color: #1D1D1D;
}

.box:hover{
    background-color: #CE3C46;
}

.box p{
    margin-top: 10px;
    font-family: 'Nunito Sans', sans-serif;
    padding-left: 40px;
    padding-right: 40px;
    color: white;
    font-size: 14px
}

.box a p{
    cursor: pointer;
    font-size: small;
}

.box a p:hover{
    font-size: small;
    text-decoration: underline;
}

.servi .row{
    justify-content: center;
    align-items: center;
    display: flex;
}

.rights{
    font-family: 'Nunito Sans', sans-serif;
}
.rights h1{
    color: white;
    font-weight: 900;
}

.rights p{
    margin-top: 20px;
    padding-left: 10%;
    padding-right: 10%;
    color: rgba(197,197,197,0.8) ;
}

.feat h1{
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 900;
    letter-spacing: 1px;
}
.feat p{
    color: #CE3C46;
    font-family: 'Nunito Sans', sans-serif;
}
@media only screen and (min-width: 701px) and (max-width: 1000px){
    .log{
        position: absolute;
        top:25%;
        left:5%
    }
    
    .log img{
        width: 250px;
    }
}



@media only screen and (min-width: 421px) and (max-width: 700px){
    .log{
        position: absolute;
        top:15%;
        left:5%
    }
    
    .log img{
        width: 150px;
    }
}
@media only screen and  (max-width: 420px){
    .log{
        position: absolute;
        top:10%;
        left:5%
    }
    
    .log img{
        width: 120px;
    }
}
@media only screen and (max-width: 400px){
    .log{
        display: none;
        position: absolute;
        top:14%;
        left:5%
    }
    
    .log img{
        width: 150px;
        display: none;
    }
}


