@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.righ{
    font-family: 'Nunito Sans', sans-serif;
}

.headtext{
    position: absolute;
    top:18%;
    left: 5%;
}

.p1ad{
    font-size: 6rem;
    font-weight: bold;
    color: #CE3C46;
    font-family: 'Nunito Sans', sans-serif;
}

.p2ad{
    font-size: 6rem;
    font-weight: bold;
    color:rgb(255, 255, 255);
    font-family: 'Nunito Sans', sans-serif;
}


.cot{
    box-sizing: border-box;
    background-image: url('../Assets/porti.jpg');
    /* background: rgba(1, 1, 1, 0.4); */
    width: 100%;
    height: 70vh;
    /* background:fixed; */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  
}
.bli{
    height: 70vh;
    width: 100%;
    background-size: cover;
    margin: 0;
    padding: 0;
    /* background: rgba(1, 1, 1, 0.2); */
}
.card{
    background-color: #111111;
    margin:10px;
    /* style={{width: "20rem",border:"",borderRadius:"0px",padding:"5px"}} */
}

.card a{
    color: #CE3C46;
    text-decoration: none;
  /* margin-top: 20px; */
}

.card-title{
    color: #CE3C46;
}

.card-text{
    color: white;
}

@media only screen and (max-width: 600px){

    
.cot{
    box-sizing: border-box;
    background-image: url('../Assets/porti.jpg');
    /* background: rgba(1, 1, 1, 0.4); */
    width: 100%;
    height: 50vh;
    /* background:fixed; */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  
}
.bli{
    height: 50vh;
    width: 100%;
    background-size: cover;
    margin: 0;
    padding: 0;
    background: rgba(1, 1, 1, 0.2);
}


    .headtext{
        position: absolute;
        top:18%;
        left: 5%;
    }
    
    .p1ad{
        font-size: 3rem;
        font-weight: bold;
        color: #CE3C46;
        font-family: 'Nunito Sans', sans-serif;
    }
    
    .p2ad{
        font-size: 3rem;
        font-weight: bold;
        color:rgb(255, 255, 255);
        font-family: 'Nunito Sans', sans-serif;
    }
    
    
}
