@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
::-webkit-scrollbar {
    display: none;
}
body{
    background-color: #06090E;
    
}
.cohead{
    box-sizing: border-box;
    background-image: url('../Assets/contact.jpg');
    /* background: rgba(1, 1, 1, 0.4); */
    width: 100%;
    /* height: 100vh; */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  
}

.cax{
    background-color:white
}
.blim{
    /* height: 100vh; */
    width: 100%;
    /* background-size: cover; */
    margin: 0;
    padding: 0;
    background: rgba(1, 1, 1,0.7);
}

.coh{
    margin-top: 40px;
    color: white;
    font-size: xx-large;
    font-weight:bolder;
    word-spacing: 2.5px;
    letter-spacing: 1px;
    font-family: 'Nunito Sans', sans-serif;
}

.poh{
    color: white;
    font-size: x-large;
    font-weight: lighter;
    font-family: 'Nunito Sans', sans-serif;
}

.secco{
    height: 100vh;
    position: relative;
    overflow: hidden;
    /* width: 100%; */
    background-color: #CE3C46;
}

.rosc1{
    margin-top: 30px;
    margin-left: 40px;
    font-size: large;
    font-weight:bold;
}

.rosc{
    margin-top: 70px;
    margin-left: 40px;
    font-size: large;
    font-weight:bold;
}
.rosc1 p{
    margin-bottom: 0px;
    color: #4E4E4E;
    font-family: 'Nunito Sans', sans-serif;
}
.rosc p{
    color: #4E4E4E;
    margin-bottom: 0px;
    font-family: 'Nunito Sans', sans-serif;
}
.rosc input{
    width:30vw;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.rosc1 input{
    width: 30vw;
    border-left: 0px;
    border-top: 0px;
    border-right: 0px;
    border-radius: 0px;
}

.rab{
    color: #4E4E4E;
    margin-top: 60px;
    margin-left: 40px;
    font-family: 'Nunito Sans', sans-serif;

}

.rab p{
    font-size: xx-large;
}

.rab label {
    
    font-size: large;
    margin-left: 10px;
    /* font-weight: 900; */
    font-family: 'Nunito Sans', sans-serif;
}

.rab input{
    width: 15px;
    height: 15px;
}

.thirr{
    margin-left: 40px;
    margin-top: 40px;
}
.thirr p{
    color: #4E4E4E;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 900;
    font-size: larger;

}

.thirr input{
    width: 50vw;
    height: 12vh;
}



.btnl{
    margin-top: 10px;
    margin-bottom: 10px;
}

@media only screen and (min-width: 824px){
    .btnl{
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-family: 'Nunito Sans', sans-serif;
    font-size: small;
    margin-top: 10px;
    margin-bottom: 10px;
}
}

@media only screen and (min-width: 600px) and (max-width:823px) {
    .secco{
        height: 115vh;
    }
    .rab label {
    
        font-size: large;
        margin-left: 10px;
        /* font-weight: 900; */
        font-family: 'Nunito Sans', sans-serif;
    }
    .thirr input{
        width: 55vw;
        height: 8vw;
    }
    
    .rab input{
        width: 10px;
        height: 10px;
    }
    .btnl{
        position: absolute;
        bottom: 8px;
        right: 8px;
        font-family: 'Nunito Sans', sans-serif;
        font-size: small;
    }
}

@media only screen and (max-width: 600px) {

    .rosc input{
        width:72vw;
        
    }
    .rosc1 input{
        width:72vw;
        
    }
    .secco{
        /* height: 100vh; */
        /* width: 100%; */
        display: none;
        /* background-color: #CE3C46; */
    }
    .rosc{
        margin-left: 0px;
    }

    
    
    .rosc1{
        margin-left: 0px;
        margin-top: 10px;
    }

    .rab{
        margin-left: 0px;
       
    }
    .rab p{
        font-size: larger;
    }
    .rab label {
        font-size: large;
        margin-left: 10px;
        /* font-weight: 900; */
        font-family: 'Nunito Sans', sans-serif;
    
    }

    .thirr{
        margin-left: 0px;
        margin-top: 40px;
    }

    .thirr input{
        width: 70vw;
        height: 18vh;
    }
  }