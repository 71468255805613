@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.pics img{
    cursor: pointer;
    width: 200px;
}
.xar{
    color: white;
    font-size: xx-large;
    font-weight: 400;
    font-family: 'Nunito Sans', sans-serif;

}

.pics img :hover{
    scale: 0.8;
}
.pics .x1{
    color:white;
    font-size: small;
    /* margin-left: 10px; */

}

.pics .x2{
    color: rgb(204, 53, 53);
    margin-left: 10px;
}

.pics a{
    color: white;
    cursor: pointer;
    margin-left: 10px;
}

/* .pics a :hover{
    color: rgb(163, 45, 45);
    scale: 1.2;

} */

.pics a:hover{
    scale: 1.2;
}