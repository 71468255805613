@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');


.mobilero{
    
    font-family: 'Nunito Sans', sans-serif;
}
 
.mobilero h1{
    color: white;
    font-weight: 900;
    margin-bottom: 50px;
}

.mobilero .p1mo{
    color: #CE3C46;
    font-size: larger;
}

.mobilero .p2mo{
    color: white;
    /* font-size: larger; */
}


.imgmo{
    width: 85%;
}
@media only screen and (max-width: 600px) {
    .imgmo{
        width: 70%;
    }

    .x{
        margin-top: 15px;
    }
  }