@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

   .scrollingh {
   
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    &::-webkit-scrollbar {
        display: none;
      }
  
    .imgs {
      position: relative;
      display: inline-block;
      justify-content: center;
     
      align-items: center;
      /* display: flex; */
    }

    .imgs::after {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0),rgba(6, 0, 0, 0.95));
    }
  }
  
  .imgs p{
    font-family: 'Nunito Sans', sans-serif;
    font-size: x-large;
    /* color: black; */
    color: rgba(158, 113, 113, 0.868);
    position: absolute;
    justify-content: center;
    cursor: pointer;
    bottom:2px;
    z-index: 20;
    font-weight: 900;
    left :36%
  }



  .imgs p:hover{
    color: rgb(255, 255, 255,1);
  }

  .p2{
    left: 30%;
  }

  /* .x11{
    position: absolute;
    cursor: pointer;
    bottom:0px;
    width: 100%;
    height: 10vh;
    background-color: rgb(33, 44, 56,0.4);
  } */

  @media only screen and (max-width: 600px) {
    .imgs p{
      left: 37%;
      font-size: large;
      bottom: 0px;
    }
  }