@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.caser{
    justify-content: center;
    align-items: center;

    width: 20rem;
    height: 2rem;
    background-color: #6D7174;
    color: white;
    /* font-size: medium; */
    margin: 20px;
    font-family: 'Nunito Sans', sans-serif;
}
.caser p{
    margin-top: 4px;
}

.caser:hover{
    scale: 0.9;
    background-color: #CE3C46;
}

.rio{
    width:35vw;
    /* height: 20rem; */

}

@media only screen and (max-width:600px) {
    .caser{
        /* height: 2.5rem; */
    }

    .rio{
        width:70vw;
        /* height: 20rem; */
    
    }
  }