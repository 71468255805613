@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.aboutco{
    justify-content: center;
    align-items: center;
    display: flex;
    color: white;
    height: 100vh;
    width: 100%;
}

.roabout h1{
    font-family: 'Nunito Sans', sans-serif;
    word-spacing: 2px;
    font-weight: bolder;
    margin-top: 30px;
}

.roabout p{

    font-family: 'Nunito Sans', sans-serif;
    color: #C5C5C5;
    padding: 0% 15% 0% 15%;
    margin-top: 25px;

}
.roabout p:hover{

    color: white;

}

.logst{
    width:50%
}
.aboutsecrow h1{
    color: white;
    font-weight: 900;
    letter-spacing: 2px;
    word-spacing: 5px;
    font-family: 'Nunito Sans', sans-serif;
}

.aboutsecrow2{
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutsecrow2 img {
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
}

@media only screen and (min-width: 601px) and (max-width:1000px) {
    .roabout p{
        padding: 0% 10% 0% 10%;
    }
    .roabout h1{
        margin-top: 10px;
    }
  }


  @media only screen and (max-width:600px) {
    .roabout p{
        padding: 0% 5% 0% 5%;
    }

    .logst{
        margin-top: 7vh;
    }

    .roabout h1{
        margin-top: 15px;
    }
  }