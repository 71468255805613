@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');

.footer{
    background: #1D1F21;
    color:white;
    
    .links{

      

      margin-top: 15px;
     
      ul {list-style-type: none;
        
      }
      li a{
        
        color: white;
        transition: color .2s;
        &:hover{
          text-decoration:none;
          color:#4180CB;
          }
      }
    }  
    .about-company{
      i{font-size: 25px;}
      a{
        color:white;
        transition: color .2s;
        &:hover{color:#4180CB}
      }
    } 
    .location{
      i{font-size: 18px;}
    }
    /* .copyright p{border-top:1px solid rgba(255,255,255,.1);}  */
  }
  
  .hre{
    background-color:white
  }
.line1{
  margin-bottom: 15px;
  width: 100%;
  height: 1px;
  background-color: #a4a4a4;
}
.linke{
  margin-top: 10px;
}
.p{
  font-family: 'Nunito Sans', sans-serif;
  font-size:medium;
  font-weight:lighter;
}
.p a{
  text-decoration: none;
  color: #a4a4a4;
}

.p a:hover{
  /* text-decoration: none; */
  color: white;
}

.cl{
  color: #a4a4a4;
}
.cl:hover{
  color: white;
}
.line2{
  
  margin-top: 30px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  width: 100%;
  height: 1px;
  background-color:  #a4a4a4;
}

.le{
  margin-left:10px
}

.le p{
  font-family: 'Nunito Sans', sans-serif;
}


.hl{
  font-family: 'Nunito Sans', sans-serif;
}

.co:hover{
  scale: 1.2;
}

.copyrightfo{
  margin-top: 5px;
  font-family: 'Nunito Sans', sans-serif;
  color: #a4a4a4;
}
.copyrightfo :hover{
  color:white
}

@media only screen and (max-width: 600px) {
  .le{
    margin-left: 0px;
  }
}