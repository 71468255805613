@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');


.nav-link{
    color: white;
    font-family: 'Nunito Sans', sans-serif;
    position: relative;
}
.nav-link:hover{
    color: #CE3C46;
    position: relative;
}

.na{
    background-color: rgba(1, 1, 1, 0.2);
}

.naz{
    background-color: rgba(1, 1, 1, 1);
}
.navbar-toggler-icon{
    --bs-navbar-toggler-icon-bg: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e);
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='white' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); */
}
.nav-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 4px;
    background-color: #CE3C46;
    bottom: 0;
    /* color: red; */
    left: 0;
    
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
  }

  
.nav-link:hover::before {
    transform-origin: left;
    transform: scaleX(1);
  }